import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Bio from "../components/bio";

export const Projects = () => {
  return (
    <Layout>
      <Bio />
      <Seo title={"Projekte von Lars Kölpin-Freese"} />
      <div>
        <table>
          <tr>
            <th>Jahr</th>
            <th>Projekt</th>
          </tr>
          <tbody>
            <tr>
              <td>2016</td>
              <td>2016</td>
            </tr>
            <tr>
              <td>2018</td>
              <td>2016</td>
            </tr>
            <tr>
              <td>2019</td>
              <td>2016</td>
            </tr>
            <tr>
              <td>2020</td>
              <td>2016</td>
            </tr>
            <tr>
              <td>2021</td>
              <td>2016</td>
            </tr>
            <tr>
              <td>2022</td>
              <td>2016</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout>
  );
};
export default Projects;
